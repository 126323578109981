module.exports = {
  siteTitle: 'Devologic', // <title>
  manifestName: 'Devologic',
  manifestShortName: 'Devologic', // max 12 characters
  manifestDescription: 'Développement Front End, Web, Mobile, Multimédia',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'fullscreen',
  manifestIcon: 'src/assets/img/my_logo.png',
  authorName: 'Devologic',
  heading1: 'Développement Front End',
  heading2: 'Web Mobile Multimédia',
  // social
  socialLinks: [
    {
      icon: 'fa-maxcdn',
      name: 'Malt',
      url: 'https://www.malt.fr/profile/laurentgarnier3?overview=true',
    },
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/laurent-garnier-b079a916/',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:laurentgarnierbx@gmail.com',
    },
  ],
};
